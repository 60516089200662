<template>
  <div v-if="model.program.name" class="row">
    <div class="col-md-12 d-flex justify-content-center">
      <div class="breadcrumb-course">
        <span @click="gotToPrograms">{{ model.program.name }}</span>
        <span v-if="model.sosCategoryAcademicProgram && model.sosCategoryAcademicProgram.name">
          <span class="follow-icon"><i class="far fa-angle-right"></i></span>
          <span @click="updateWizard('wizard-step2')">{{ model.sosCategoryAcademicProgram.name }}</span>
        </span>
        <span v-if="model.board && model.board.name">
          <span class="follow-icon"><i class="far fa-angle-right"></i></span>
          <span @click="updateWizard('wizard-step2')">{{ model.board.name }}</span>
        </span>

        <span v-if="model.courseGroup && model.courseGroup.name">
          <span><i class="far fa-angle-right"></i></span>
          <span @click="updateWizard('wizard-step3')">{{ model.courseGroup.name }}</span>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    model: null,
  },
  methods: {
    gotToPrograms() {
      let name = this.$route.name === "FreeTrialExam" || this.$route.name === "FreeTrialClass" ? "free-trial" : "category";
      this.$router.push({ name: name }).catch(() => {});
    },
    updateWizard(wizard) {
      this.$emit("onUpdateWizard", wizard);
    },
  },
};
</script>

<style></style>
